<template>
  <div class="container">
    <div class="top">
      <div class="top_title">
        <div class="title_1"> {{topInfo.title_1}} </div>
        <div class="title_1"> {{topInfo.title_2}} </div>
        <div class="title_1"> {{topInfo.title_3}} </div>
      </div>
    </div>
    <div class="mid">
      <div class="mid_left">
        <div class="mid_left_title"> {{midInfo.title}} </div>
        <div class="mid_left_content"> {{midInfo.content}} </div>
        <div class="mid_left_btn" @click.stop="tohlwj(midInfo)">
          <div class="detail">查看详情</div>
          <img class="icon" src="https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_right_green.png">
        </div>
      </div>
      <div class="mid_right">
        <img class="left_img" :src="midInfo.img_1">
        <img class="right_img" :src="midInfo.img_2">
      </div>
    </div>
    <div class="bottom">
      <div class="item" v-for="item in productList" :key="item">
        <div class="item_title"> {{item.title}} </div>
        <div class="item_btn" @click.stop="toDetail(item)">
          <div class="detail">查看详情</div>
          <img class="icon" src="https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_right_green.png">
        </div>
        <div class="item_img">
          <img :src="item.img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "product",
    data(){
      return{
        topInfo:{
          title_1: '以实体医院为依托',
          title_2: '小程序为载体',
          title_3: '线上线下统一入口',
        },
        midInfo:{
          title: '「互联网+」医疗服务平台',
          content: '小程序为载体，连接患者、医生端，为医院打造线上线下统一入口。区别于传统智慧医院单一产品服务形态，海南玉螺借助腾讯科技生态优势，不断深延医疗服务场景，持续丰富医院服务业态，满足患者多元就医需求。',
          img_1: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_1.png',
          img_2: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_2.png',
        },
        productList:[
          {
            id: 1,
            title: '处方流转',
            img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_cflz.png'
          },
          {
            id: 2,
            title: '「互联网+」护理',
            img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwhl.png'
          },
          {
            id: 3,
            title: '健康管理',
            img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_jkgl.png'
          },
        ]
      }
    },
    methods:{
      tohlwj(){
        this.$router.push({ path: '/product_ylfw' })
      },
      toDetail(item){
        if(item.id == 1){
          this.$router.push({ path: '/product_cflz' })
        } else if(item.id == 2){
          this.$router.push({ path: '/product_hlwjhl' })
        } else if(item.id == 3){
          this.$router.push({ path: '/product_jkgl' })
        }
      }
    }
  };
</script>

<style lang="stylus" scoped>

  .container{
    display flex
    flex-flow column
    background #F5F5F7
    font-family Alibaba PuHuiTi 2.0
    font-weight normal
    .top{
      height 700px
      background url("https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_top_bg.png") no-repeat
      background-size 100% 100%
      padding 0 140px
      .top_title{
        display flex
        flex-flow column
        margin-top 160px
        .title_1{
          font-size 56px
          line-height 67px
          color #343A40
        }
      }
    }
    .mid{
      display flex
      box-sizing border-box
      margin -80px 140px 0 140px
      border-radius 24px
      padding 50px 50px 0 50px
      background #ffffff
      justify-content space-between
      .mid_left{
        display flex
        flex-flow column
        width 640px
        margin-top 253px
        .mid_left_title{
          font-size 40px
          line-height 56px
          color #343A40
        }
        .mid_left_content{
          margin-top 40px
          font-size 20px
          font-weight 400
          line-height 34px
          color #6C757D
        }
        .mid_left_btn{
          display flex
          margin-top 40px
          align-items center
          cursor pointer
          .detail{
            font-size 24px
            line-height 33px
            color #17A2B8
          }
          .icon{
            margin-left 8px
            width 21px
            height 21px
          }
        }
      }
      .mid_right{
        display flex
        .left_img{
          width 317px
          margin-top 95px
        }
        .right_img{
          width 309px
          margin 179px 68px 0 60px
        }
      }
    }
    .bottom{
      display flex
      box-sizing border-box
      justify-content space-between
      padding 16px 140px 140px 140px
      .item{
        width 528px
        display flex
        flex-flow column
        background #ffffff
        border-radius 24px
        box-sizing border-box
        padding 50px
        .item_title{
          font-size 40px
          line-height 56px
          color #343A40
        }
        .item_btn{
          display flex
          align-items center
          margin-top 20px
          cursor pointer
          .detail{
            font-size 24px
            line-height 33px
            color #17A2B8

          }
          .icon{
            margin-left 8px
            width 21px
            height 21px
          }
        }
        .item_img{
          display flex
          justify-content center
          margin-top 50px
          img{
            width 350px
            height 700px
          }
        }
      }
    }
  }
</style>
